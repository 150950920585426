import axiosInstance from '../../../helpers/axios';

export const getPins = (query) => {
  return axiosInstance.get(`pin/get_by_query${query}`);
};

export const getComments = (query) => {
  return axiosInstance.get(`pin/get_by_query_comment?${query}`);
};

export const getLatestPins = (query) => {
  return axiosInstance.get(`pin/latest${query ? `?board_id=${query}` : ''}`);
};

export const postPin = (data) => {
  return axiosInstance.post('pin/store', data);
};

export const postLike = (data) => {
  return axiosInstance.post('pin/store-like', data);
};

export const postComment = (data) => {
  return axiosInstance.post('pin/store-comment', data);
};

export const patchPin = ({ pinId, data }) => {
  return axiosInstance.patch(`pin/${pinId}`, data);
};

export const deletePin = (pinId) => {
  return axiosInstance.delete(`pin/${pinId}`);
};

export const deleteLikes = (pinId) => {
  return axiosInstance.delete(`pin/${pinId}/like`);
};
