import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const textStyles = {
  h1: {
    fontSize: { base: '2.441rem', md: '3.052rem' },
  },
  h2: {
    fontSize: { base: '1.953rem', md: '2.441rem' },
  },
  h3: {
    fontSize: { base: '1.563rem', md: '1.953rem' },
  },
  h4: {
    fontSize: { base: '1.25rem', md: '1.563rem' },
  },
  h5: {
    fontSize: { base: '1rem', md: '1.25rem' },
  },
  h6: {
    fontSize: { base: '0.8rem', md: '1rem' },
  },
  'body-xl': {
    fontSize: { base: '1.563rem', md: '1.953rem' },
  },
  'body-lg': {
    fontSize: { base: '1.25rem', md: '1.563rem' },
  },
  'body-md': {
    fontSize: { base: '1rem', md: '1.25rem' },
  },
  'body-sm': {
    fontSize: { base: '0.8rem', md: '1rem' },
  },
};

const theme = extendTheme({
  config,
  styles: {
    global: (props) => {
      return {
        body: {
          background: mode('white', 'gray.900')(props),
          fontWeight: 'normal',
          minH: '100vh',
          overflowX: 'hidden',
        },
        '#root': {
          minH: '100vh',
          display: 'flex',
          flexDirection: 'column',
        },
        '.quill': {
          '.ql-toolbar.ql-snow': {
            '& .ql-formats': {
              my: '1',
              bg: mode('gray.100', 'gray.800')(props),
              border: 'none',
              rounded: 'md',
              '.ql-picker': {
                color: mode('gray.500', 'whiteAlpha.600')(props),
                '&.ql-font .ql-picker-label, &.ql-size .ql-picker-label': {
                  border: 'none',
                  fontSize: 'sm',
                  ':hover': {
                    color: 'blue.500',
                  },
                },
                '.ql-picker-options': {
                  top: '8',
                  bg: mode('white', 'gray.800')(props),
                  shadow: mode('md', 'dark-lg')(props),
                  rounded: 'md',
                  borderColor: mode('gray.200', 'whiteAlpha.300')(props),
                  '.ql-picker-item.ql-selected, .ql-picker-item:hover': {
                    color: 'blue.500',
                  },
                },
              },
              '.ql-header': {
                fontSize: 'sm',
                color: mode('gray.500', 'whiteAlpha.600')(props),
                ':hover': {
                  color: 'blue.500',
                },
              },
              svg: {
                '.ql-stroke, .ql-fill, .ql-thin': {
                  stroke: mode('gray.400', 'whiteAlpha.400')(props),
                },
                '.ql-fill.ql-color-label': {
                  stroke: mode('gray.300', 'whiteAlpha.400')(props),
                },
              },
              'button:hover': {
                '.ql-stroke, .ql-fill, .ql-thin': {
                  stroke: 'blue.500',
                },
              },
              '.ql-color.ql-picker.ql-color-picker:hover': {
                '.ql-stroke, .ql-fill, .ql-thin': {
                  stroke: 'blue.500',
                },
              },
              '.ql-background.ql-picker.ql-color-picker:hover': {
                '.ql-fill ql-color-label': {
                  fill: 'blue.500',
                },
                '.ql-stroke, .ql-fill, .ql-thin': {
                  stroke: 'blue.500',
                },
              },
            },
          },
          '.ql-container.ql-snow .ql-editor': {
            fontSize: '1rem',
            '&.ql-blank::before': {
              fontStyle: 'normal',
              color: mode('gray.400', 'whiteAlpha.400')(props),
            },
          },
        },
      };
    },
  },
  textStyles,
  fonts: {
    body: "'Hind', sans-serif",
    heading: "'Hind', sans-serif",
    mono: "'Hind', monospace",
  },
  colors: {
    'rob-roy': {
      50: '#fefcf8',
      100: '#fdf8f1',
      200: '#faefdb',
      300: '#f7e5c5',
      400: '#f0d19a',
      500: '#eabd6e',
      600: '#d3aa63',
      700: '#b08e53',
      800: '#8c7142',
      900: '#735d36',
    },
    juniper: {
      50: '#f7fafa',
      100: '#f0f5f5',
      200: '#d8e5e5',
      300: '#c1d5d6',
      400: '#93b6b7',
      500: '#649798',
      600: '#5a8889',
      700: '#4b7172',
      800: '#3c5b5b',
      900: '#314a4a',
    },
    cranberry: {
      50: '#fef7f8',
      100: '#fceff2',
      200: '#f8d7dd',
      300: '#f3bec9',
      400: '#ea8ea1',
      500: '#e15d78',
      600: '#cb546c',
      700: '#a9465a',
      800: '#873848',
      900: '#6e2e3b',
    },
    'brink-pink': {
      50: '#fef7f8',
      100: '#fdeff2',
      200: '#fad7de',
      300: '#f6bec9',
      400: '#f08ea1',
      500: '#e95d79',
      600: '#d2546d',
      700: '#af465b',
      800: '#8c3849',
      900: '#722e3b',
    },
    'polo-blue': {
      50: '#f8fafd',
      100: '#f1f6fa',
      200: '#dce8f3',
      300: '#c7daec',
      400: '#9dbfdd',
      500: '#73a3cf',
      600: '#6893ba',
      700: '#567a9b',
      800: '#45627c',
      900: '#385065',
    },
    azure: {
      50: '#f5f7fb',
      100: '#eceff6',
      200: '#cfd6e9',
      300: '#b2bedc',
      400: '#798dc1',
      500: '#3f5ca7',
      600: '#395396',
      700: '#2f457d',
      800: '#263764',
      900: '#1f2d52',
    },
    'cloud-burst': {
      50: '#f4f5f7',
      100: '#e8eaee',
      200: '#c7ccd5',
      300: '#a5adbc',
      400: '#616f8a',
      500: '#1d3158',
      600: '#1a2c4f',
      700: '#162542',
      800: '#111d35',
      900: '#0e182b',
    },
    heliotrope: {
      50: '#faf7fe',
      100: '#f6effe',
      200: '#e8d8fc',
      300: '#dac0fa',
      400: '#be90f7',
      500: '#a261f3',
      600: '#9257db',
      700: '#7a49b6',
      800: '#613a92',
      900: '#4f3077',
    },
    'moody-blue': {
      50: '#f8f8fd',
      100: '#f2f1fa',
      200: '#dedcf3',
      300: '#cac7ec',
      400: '#a29ddd',
      500: '#7a73cf',
      600: '#6e68ba',
      700: '#5c569b',
      800: '#49457c',
      900: '#3c3865',
    },
    'fuchsia-pink': {
      50: '#fcf7fc',
      100: '#faeffa',
      200: '#f2d8f2',
      300: '#eac0eb',
      400: '#da91db',
      500: '#ca62cc',
      600: '#b658b8',
      700: '#984a99',
      800: '#793b7a',
      900: '#633064',
    },
    plum: {
      50: '#fbf5fa',
      100: '#f6ecf5',
      200: '#e9cfe5',
      300: '#dcb2d6',
      400: '#c179b7',
      500: '#a73f98',
      600: '#963989',
      700: '#7d2f72',
      800: '#64265b',
      900: '#521f4a',
    },
    whiskey: {
      50: '#fefaf8',
      100: '#fdf5f1',
      200: '#f9e7dc',
      300: '#f5d8c7',
      400: '#eebb9e',
      500: '#e79e74',
      600: '#d08e68',
      700: '#ad7757',
      800: '#8b5f46',
      900: '#714d39',
    },
    'vida-loca': {
      50: '#f7faf4',
      100: '#eef5e9',
      200: '#d5e6c7',
      300: '#bbd6a6',
      400: '#89b863',
      500: '#569920',
      600: '#4d8a1d',
      700: '#417318',
      800: '#345c13',
      900: '#2a4b10',
    },
    emerald: {
      50: '#f7fdf9',
      100: '#f0faf3',
      200: '#d9f3e2',
      300: '#c1ebd0',
      400: '#93ddac',
      500: '#65ce89',
      600: '#5bb97b',
      700: '#4c9b67',
      800: '#3d7c52',
      900: '#316543',
    },
    gray: {
      50: '#F9FAFB',
      100: '#F3F4F6',
      200: '#E5E7EB',
      300: '#D1D5DB',
      400: '#9CA3AF',
      500: '#6B7280',
      600: '#4B5563',
      700: '#374151',
      800: '#1F2937',
      900: '#111827',
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'semibold',
        rounded: 'full',
      },
      variants: {
        customSolid: (props) => {
          const { colorScheme: colors } = props;
          return {
            transition: '0.5s',
            color: 'white',
            bgSize: '200% auto',
            bgGradient: `linear(to-r, ${colors[0]}.500 0%, ${colors[1]}.500 51%, ${colors[2]}.500 100%)`,
            _hover: {
              bgPosition: 'right center',
              _disabled: {
                bgGradient: `linear(to-r, ${colors[0]}.500 0%, ${colors[1]}.500 51%, ${colors[2]}.500 100%)`,
              },
            },
            _active: {
              bgGradient: `linear(to-r, ${colors[0]}.600 0%, ${colors[1]}.600 51%, ${colors[2]}.600 100%)`,
              bgPosition: 'right center',
            },
          };
        },
      },
      sizes: {
        xl: {
          h: 14,
          minW: 14,
          fontSize: 'lg',
          px: 8,
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            borderColor: 'gray.300',
            borderRadius: 'full',
          },
        },
      },
    },
    Tag: {
      sizes: {
        lg: {
          container: {
            fontSize: 'lg',
          },
        },
        xl: {
          container: {
            minH: 10,
            minW: 10,
            fontSize: 'lg',
            borderRadius: 'md',
            px: '3',
          },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: 'lg',
      },
    },
    Tabs: {
      variants: {
        'solid-rounded': (props) => {
          const { colorScheme: c } = props;
          return {
            tab: {
              borderRadius: 'xl',
              borderWidth: '2px',
              borderColor: mode(`${c}.600`, `${c}.300`)(props),
              _selected: {
                borderColor: 'transparent',
              },
            },
          };
        },
        custom: (props) => {
          const { colorScheme: c } = props;
          const isBlue = c === 'blue';
          return {
            tablist: {
              borderRadius: 'lg',
              bg: mode(isBlue ? 'gray.100' : `${c}.50`, isBlue ? 'gray.800' : `${c}.50`)(props),
              p: '1',
            },
            tab: {
              borderRadius: 'lg',
              fontWeight: 'medium',
              color: mode(isBlue ? 'gray.400' : `${c}.500`, isBlue ? 'gray.400' : `${c}.500`)(props),
              _selected: {
                color: mode(isBlue ? 'gray.800' : `white`, isBlue ? 'white' : `${c}.100`)(props),
                bg: mode(isBlue ? 'white' : `${c}.500`, isBlue ? 'gray.900' : `${c}.500`)(props),
              },
            },
          };
        },
      },
    },
  },
});

export default theme;
