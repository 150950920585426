/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchPins,
  createPin,
  updatePin,
  removePin,
  fetchLatesPins,
  createLike,
  removesLike,
  fetchComments,
  createComment,
} from './action';

export const pinSlice = createSlice({
  name: 'pin',
  initialState: {
    pins: [],
    comments: [],
    isLoading: true,
    isCommentLoading: true,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchPins.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchPins.fulfilled]: (state, { payload }) => {
      state.pins = payload;
      state.isLoading = false;
    },
    [fetchPins.rejected]: (state) => {
      state.isLoading = false;
      state.error = true;
    },
    [fetchComments.pending]: (state) => {
      state.isCommentLoading = true;
      state.error = null;
    },
    [fetchComments.fulfilled]: (state, { payload }) => {
      state.comments = payload;
      state.isCommentLoading = false;
    },
    [fetchComments.rejected]: (state) => {
      state.isCommentLoading = false;
      state.error = true;
    },
    [fetchLatesPins.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchLatesPins.fulfilled]: (state, { payload }) => {
      state.pins = payload;
      state.isLoading = false;
    },
    [fetchLatesPins.rejected]: (state) => {
      state.isLoading = false;
      state.error = true;
    },
    [createPin.fulfilled]: (state, { payload }) => {
      state.pins.push(payload);
    },
    [createComment.fulfilled]: (state, { payload }) => {
      state.comments.push(payload);
      state.pins = state.pins.map((pin) =>
        pin._id === payload.pin_id ? { ...pin, commentsCount: pin.commentsCount + 1 } : pin
      );
    },
    [createLike.fulfilled]: (state, { payload }) => {
      state.pins = state.pins.map((pin) =>
        pin._id === payload.pin_id ? { ...pin, is_liked: true, likesCount: pin.likesCount + 1 } : pin
      );
    },
    [updatePin.fulfilled]: (state, { payload }) => {
      const findBoard = state.pins.find((pin) => pin._id === payload._id);
      Object.assign(findBoard, payload);
    },
    [removePin.fulfilled]: (state, { payload }) => {
      const newPins = state.pins.filter((pin) => pin._id !== payload);
      state.pins = newPins;
    },
    [removesLike.fulfilled]: (state, { payload }) => {
      state.pins = state.pins.map((pin) =>
        pin._id === payload ? { ...pin, is_liked: false, likesCount: pin.likesCount - 1 } : pin
      );
    },
  },
});

export default pinSlice.reducer;
